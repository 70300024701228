/*
    -----------------------------------------------------------------
                                SANS FONTS
    -----------------------------------------------------------------
*/

/*
    LIGHT FONTS
*/

@font-face {
  font-family: 'Roche Sans';
  src: local('Roche Sans Light'), local('RocheSans-Light'),
    url('fonts/sans/light/RocheSans-Light.woff2') format('woff2'),
    url('fonts/sans/light/RocheSans-Light.woff') format('woff'),
    url('fonts/sans/light/RocheSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roche Sans';
  src: local('Roche Sans Condensed Light'), local('RocheSansCondensed-Light'),
    url('fonts/sans/condensed/RocheSansCondensed-Light.woff2') format('woff2'),
    url('fonts/sans/condensed/RocheSansCondensed-Light.woff') format('woff'),
    url('fonts/sans/condensed/RocheSansCondensed-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-stretch: condensed;
}

@font-face {
  font-family: 'Roche Sans';
  src: local('Roche Sans Light Italic'), local('RocheSans-LightItalic'),
    url('fonts/sans/light/RocheSans-LightItalic.woff2') format('woff2'),
    url('fonts/sans/light/RocheSans-LightItalic.woff') format('woff'),
    url('fonts/sans/light/RocheSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Roche Sans';
  src: local('Roche Sans Condensed Light Italic'),
    local('RocheSansCondensed-LightItalic'),
    url('fonts/sans/condensed/RocheSansCondensed-LightItalic.woff2')
      format('woff2'),
    url('fonts/sans/condensed/RocheSansCondensed-LightItalic.woff')
      format('woff'),
    url('fonts/sans/condensed/RocheSansCondensed-LightItalic.ttf')
      format('truetype');
  font-weight: 300;
  font-style: italic;
  font-stretch: condensed;
}

/*
      REGULAR FONTS
  */

@font-face {
  font-family: 'Roche Sans';
  src: local('Roche Sans Regular'), local('RocheSans-Regular'),
    url('fonts/sans/regular/RocheSans-Regular.woff2') format('woff2'),
    url('fonts/sans/regular/RocheSans-Regular.woff') format('woff'),
    url('fonts/sans/regular/RocheSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roche Sans';
  src: local('Roche Sans Condensed Regular'),
    local('RocheSansCondensed-Regular'),
    url('fonts/sans/condensed/RocheSansCondensed-Regular.woff2') format('woff2'),
    url('fonts/sans/condensed/RocheSansCondensed-Regular.woff') format('woff'),
    url('fonts/sans/condensed/RocheSansCondensed-Regular.ttf')
      format('truetype');
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
}

@font-face {
  font-family: 'Roche Sans';
  src: local('Roche Sans Italic'), local('RocheSans-Italic'),
    url('fonts/sans/italic/RocheSans-Italic.woff2') format('woff2'),
    url('fonts/sans/italic/RocheSans-Italic.woff') format('woff'),
    url('fonts/sans/italic/RocheSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Roche Sans';
  src: local('Roche Sans Condensed Italic'), local('RocheSansCondensed-Italic'),
    url('fonts/sans/condensed/RocheSansCondensed-Italic.woff2') format('woff2'),
    url('fonts/sans/condensed/RocheSansCondensed-Italic.woff') format('woff'),
    url('fonts/sans/condensed/RocheSansCondensed-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-stretch: condensed;
}

/*
      MEDIUM FONTS
  */

@font-face {
  font-family: 'Roche Sans';
  src: local('Roche Sans Medium'), local('RocheSans-Medium'),
    url('fonts/sans/medium/RocheSans-Medium.woff2') format('woff2'),
    url('fonts/sans/medium/RocheSans-Medium.woff') format('woff'),
    url('fonts/sans/medium/RocheSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roche Sans';
  src: local('Roche Sans Medium Italic'), local('RocheSans-MediumItalic'),
    url('fonts/sans/medium/RocheSans-MediumItalic.woff2') format('woff2'),
    url('fonts/sans/medium/RocheSans-MediumItalic.woff') format('woff'),
    url('fonts/sans/medium/RocheSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

/*
      BOLD FONTS
  */

@font-face {
  font-family: 'Roche Sans';
  src: local('Roche Sans Bold'), local('RocheSans-Bold'),
    url('fonts/sans/bold/RocheSans-Bold.woff2') format('woff2'),
    url('fonts/sans/bold/RocheSans-Bold.woff') format('woff'),
    url('fonts/sans/bold/RocheSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roche Sans';
  src: local('Roche Sans Bold Italic'), local('RocheSans-BoldItalic'),
    url('fonts/sans/bold/RocheSans-BoldItalic.woff2') format('woff2'),
    url('fonts/sans/bold/RocheSans-BoldItalic.woff') format('woff'),
    url('fonts/sans/bold/RocheSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

/*
      -----------------------------------------------------------------
                                  SERIF FONTS
      -----------------------------------------------------------------
  */

/*
      LIGHT FONTS
  */

@font-face {
  font-family: 'Roche Serif';
  src: local('Roche Serif Light'), local('RocheSerif-Light'),
    url('fonts/serif/light/RocheSerif-Light.woff2') format('woff2'),
    url('fonts/serif/light/RocheSerif-Light.woff') format('woff'),
    url('fonts/serif/light/RocheSerif-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roche Serif';
  src: local('Roche Serif Light Italic'), local('RocheSerif-LightItalic'),
    url('fonts/serif/light/RocheSerif-LightItalic.woff2') format('woff2'),
    url('fonts/serif/light/RocheSerif-LightItalic.woff') format('woff'),
    url('fonts/serif/light/RocheSerif-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

/*
        REGULAR FONTS
  */

@font-face {
  font-family: 'Roche Serif';
  src: local('Roche Serif Regular'), local('RocheSerif-Regular'),
    url('fonts/serif/regular/RocheSerif-Regular.woff2') format('woff2'),
    url('fonts/serif/regular/RocheSerif-Regular.woff') format('woff'),
    url('fonts/serif/regular/RocheSerif-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roche Serif';
  src: local('Roche Serif Italic'), local('RocheSerif-Italic'),
    url('fonts/serif/italic/RocheSerif-Italic.woff2') format('woff2'),
    url('fonts/serif/italic/RocheSerif-Italic.woff') format('woff'),
    url('fonts/serif/italic/RocheSerif-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

/*
        BOLD FONTS
  */

@font-face {
  font-family: 'Roche Serif';
  src: local('Roche Serif Bold'), local('RocheSerif-Bold'),
    url('fonts/serif/bold/RocheSerif-Bold.woff2') format('woff2'),
    url('fonts/serif/bold/RocheSerif-Bold.woff') format('woff'),
    url('fonts/serif/bold/RocheSerif-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roche Serif';
  src: local('Roche Serif Bold Italic'), local('RocheSerif-BoldItalic'),
    url('fonts/serif/bold/RocheSerif-BoldItalic.woff2') format('woff2'),
    url('fonts/serif/bold/RocheSerif-BoldItalic.woff') format('woff'),
    url('fonts/serif/bold/RocheSerif-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
