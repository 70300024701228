@font-face {
  font-family: "One Icons";
  font-style: normal;
  font-weight: 400;
  src: url("./one-icons.eot?dcb757938b1d0f51c1b49b3423511bb8?#iefix") format("embedded-opentype"),
url("./one-icons.woff2?dcb757938b1d0f51c1b49b3423511bb8") format("woff2"),
url("./one-icons.woff?dcb757938b1d0f51c1b49b3423511bb8") format("woff"),
url("./one-icons.ttf?dcb757938b1d0f51c1b49b3423511bb8") format("truetype"),
url("./one-icons.svg?dcb757938b1d0f51c1b49b3423511bb8#one-icons") format("svg");
}

.one-icons {
  font-family: "One Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: pre;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
